<template>
    <div>
        <b-sidebar backdrop aria-labelledby="sidebar-no-header-title" no-header id="add_inreceipt" width="50rem" style="direction:ltr" right title="سند قبض" shadow >
<template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>سند قبض</span>
        </div>
        <div @click="hide" id="ihidid" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>اغلاق</span>
      </div>
    </div>
            <v-divider></v-divider>
            <v-form ref="formadd">
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="12" md="4" sm="12">
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date"
                                label="تاريخ السند"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field
                            v-model="mobile"
                            :rules="fieldRules"
                            type="number"
                            label="رقم الجوال"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field
                            v-model="full_name"
                            label="الاسم الكامل"
                            :rules="fieldRules"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field
                            v-model="received_total"
                            label="المبلغ المستلم"
                            @change="doPaytype"
                            :rules="fieldRules"
                        ></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" md="4" sm="12">
                        <v-select
                            item-text="text"
                            item-value="value"
                            v-model="paytype"
                            :items="payTypeOp"
                            label="طريقة الدفع"
                            @change="checkPay"
                            :rules="fieldRules"
                            ></v-select>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field
                            v-model="paycash"
                            label="المبلغ المدفوع نقدا"
                            @change="PaymentDo()"
                        >{{ paycash }}</v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field
                            v-model="payspan"
                            label="المبلغ المدفوع بطاقة صراف"
                            @change="PaymentDo()"
                        >{{ payspan }}</v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-textarea
                            v-model="notes"
                            label="البيان"
                            :rules="fieldRules"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </div>
            </v-form>
</template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="addVoucher()" variant="success" class="ma-2" style="width:100px;">إضافة</b-button>
                </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';

export default{
    data() {
        return {
            fieldRules: [
                v => !!v || 'الحقل مطلوب',
            ],
            crmid: this.$route.params.id,
            cashing: false,
            spaning: true,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            mobile: '',
            full_name:'',
            received_total:'',
            notes:'',
            paytype:1,
            paycash:0,
            payspan:0,
            payTypeOp:[
                {
                    text: 'الدفع نقدا',
                    value: 1
                },
                {
                    text: 'الدفع عن طريق بطاقة صراف',
                    value: 2
                },
                {
                    text: 'الدفع المتعدد',
                    value: 3
                }
            ]
        }
    },
    created() {
        if(typeof this.$parent.custorder != 'undefined'){
            this.mobile = this.$parent.custorder.mobile;
            this.full_name = this.$parent.custorder.full_name;
        }
    },
    methods: {
        PaymentDo() {

            if(this.paytype == 1){
                this.paycash = this.received_total;
                this.payspan = 0;
            }else if(this.paytype == 2){
                this.payspan = this.received_total;
                this.paycash = 0;
            }else if(this.paytype == 3){
                let pc = this.paycash;
                let ps = this.payspan;
                
                if(pc != 0){
                    ps = parseFloat(this.received_total) - parseFloat(pc);
                }else if(ps != 0){
                    pc = parseFloat(this.received_total) - parseFloat(ps);
                }
                
                this.paycash = pc;
                this.payspan = ps;
            }
        },
        checkPay(){
            if(this.paytype == 1){
                this.cashing = false;
                this.spaning = true;
                this.paycash = this.received_total;
                this.payspan = 0;
            }else if(this.paytype == 2){
                this.cashing = true;
                this.spaning = false;
                this.payspan = this.received_total;
                this.paycash = 0;
            }else if(this.paytype == 3){
                this.cashing = false;
                this.spaning = false;
                this.payspan = 0;
                this.paycash = this.received_total;
            }
        },
        doPaytype(){
            if(this.paytype == 1){
                this.cashing = false;
                this.spaning = true;
                this.paycash = this.received_total;
                this.payspan = 0;
            }else if(this.paytype == 2){
                this.cashing = true;
                this.spaning = false;
                this.payspan = this.received_total;
                this.paycash = 0;
            }else if(this.paytype == 3){
                this.cashing = false;
                this.spaning = false;
                this.payspan = this.received_total;
                this.paycash = 0;
            }
        },
        addVoucher(){
            if(!this.$refs.formadd.validate()){

                return false;
            }
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type",'addVouchter');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            if(this.$route.params.id !== 'undifined'){
                post.append("data[docid]",this.$route.params.id);
                post.append("data[doc_type]","Invoice")
            }
            post.append('data[type]',1)
            post.append('data[mobile]',this.mobile)
            post.append('data[full_name]',this.full_name)
            post.append('data[amount]',this.received_total)
            post.append('data[paytype]',this.paytype)
            post.append('data[payspan]',this.payspan)
            post.append('data[paycash]',this.paycash)
            post.append('data[notes]',this.notes)
            post.append('data[date]',this.date)
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    
                    this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                    this.modal = false;
                    this.mobile = '';
                    this.full_name ='';
                    this.received_total ='';
                    this.notes = '';
                    this.$snotify.create({
                    title: 'سندات القبض',
                        body: 'تمت الاضافة بنجاح',
                        config: {
                            position: SnotifyPosition.leftTop,
                            type: SnotifyStyle.success,
                            timeout: 5000,
                            showProgressBar: true,
                            closeOnClick: true,
                        }
                    })
                    document.getElementById('ihidid').click();
                    this.$parent.getVouchersx();
                }
            )
        }
    },
}
</script>